import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Security() {
    return (
        <section id="security" className="mt-8 md:mt-16 relative bg-gray-50 z-0">
            <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="container mx-auto pb-64">
                <div className="flex flex-wrap justify-center">
                    <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
                        <FontAwesomeIcon
                            icon={ faShieldHalved }
                            size="10x"
                            className="hidden md:flex text-blue-700 absolute -top-150-px -left-100 right-auto opacity-80 text-55"
                            style={{ fontSize: 600, left: -200, top: -50, opacity: 0.5 }}
                        />
                    </div>
                    <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
                        <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                            <div className="text-blue-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white">
                                <FontAwesomeIcon icon={ faShieldHalved } className="text-xl" />
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal text-blue-500 tracking-wide">Sécurité</h3>
                        </div>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blue-gray-500 text-justify">
                            L'aspect sécurité de Timi garantit une protection fiable des données sensibles. Les reçus dématérialisés sont stockés en toute
                            sécurité dans l'application, éliminant ainsi le risque de perte de tickets papier. De plus, les mesures de sécurité avancées
                            assurent la confidentialité des informations personnelles et financières des utilisateurs.
                        </p>
                    </div>
                </div>
            </div>
            <div className="-bottom-20 top-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute top-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 0 0 0 100"></polygon>
                </svg>
            </div>
        </section>
    );
}
