import Login from "../assets/images/Pictures/login.png"
import Week from "../assets/images/Pictures/week.png"
import Stats from "../assets/images/Pictures/stats.png"

const data = [
    {
        id: "login",
        title: "Connexion",
        img: Login,
        color: "yellow",
        paragraph: "Depuis cette page tu peux accéder rapidement et en toute sécurité à ton compte personnel. En te connectant, tu auras un accès instantané à tes tickets de caisse, tes cartes de fidélité, ainsi qu'à toutes les fonctionnalités personnalisées que notre application propose. Notre interface de connexion est conçue pour être simple et conviviale, te permettant de te connecter en quelques clics. Avec Timi, tu peux gérer tes dépenses en toute simplicité, suivre tes achats passés, et consulter tes statistiques de consommation pour mieux comprendre tes habitudes d'achat.",
    },
    {
        id: "week",
        title: "Semaine",
        img: Week,
        color: "blue-gray",
        paragraph: "La page Semaine de Timi te permet d'explorer en détail tes dépenses hebdomadaires dans chaque magasin. Cette page te fournit une vue complète des prix de chaque article acheté tout au long de la semaine. En filtrant par semaine spécifique, tu pourras examiner de près tes transactions passées et identifier les tendances de consommation. Découvre comment tu as géré tes finances semaine après semaine et ajuste tes habitudes de dépenses en conséquence. Cette fonctionnalité t'offre une analyse approfondie de tes achats, t'aidant à mieux comprendre ta gestion financière et à prendre des décisions éclairées. Avec la page Semaine, tes dépenses n'auront plus de secrets pour toi, te permettant ainsi d'améliorer ton contrôle budgétaire et de créer une expérience de suivi financier plus enrichissante. Explore cette fonctionnalité dès maintenant et optimise tes finances pour une meilleure gestion de ton budget !",
    },
    {
        id: "chartpie",
        title: "Statistiques",
        img: Stats,
        color: "amber",
        paragraph: "La page Statistique Camembert de Timi permet de visualiser tes dépenses sous forme de diagramme en camembert. Ce graphique te permet d'identifier rapidement les catégories dans lesquelles tu as le plus dépensé pour chaque jour, semaine, mois ou année. Que ce soit pour l'alimentaire, les loisirs, l'informatique ou d'autres catégories, le camembert te montre clairement les proportions de tes dépenses. Grâce à cette fonctionnalité, tu peux mieux comprendre tes habitudes de consommation et cibler les domaines où tu investis le plus. Cela te permettra de prendre des décisions plus éclairées en matière de budget et d'ajuster tes dépenses en fonction de tes priorités. La page Statistique Camembert te donne un aperçu visuel et pratique de tes dépenses, te permettant ainsi de suivre facilement l'évolution de tes habitudes de consommation au fil du temps.",
    },
];

export default function UseLeft({ type }: Readonly<{ type: string }>) {
    const item = data.find((i) => i.id === type);

    return (
        <section className="mt-20 md:mt-20 relative bg-white-50">
            <div className="flex flex-wrap items-center">
                <div className="w-full sm:w-10/12 md:w-6/12 px-4 ml-auto mr-auto">
                    <h3 className={`text-3xl mb-2 font-semibold leading-normal text-${item?.color}-500 tracking-wide reveal-5`}>{item?.title}</h3>
                    <p className="text-lg font-light text-justify leading-relaxed mb-4 text-blue-gray-500 reveal-6">{item?.paragraph}</p>
                </div>

                <div className="w-10/12 md:w-4/12 lg:w-3/12 px-12 md:px-4 ml-auto mt-8">
                    <div className="relative flex flex-col min-w-0 break-words bg-transparent mb-6 rounded-lg">
                        <img
                            alt="..."
                            src={item?.img}
                            style={{
                                transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                            }}
                            width={200}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
