import React, { useEffect, useMemo } from "react";

export default function Wrapper({children} : Readonly<{children: React.ReactNode}>) {
    const threshold = 0.1;
    const options = useMemo(
        () => ({
            root: null,
            rootMargin: "0px",
            threshold,
        }),
        [threshold]
    );

    const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > threshold) {
                entry.target.classList.remove("reveal");
                observer.unobserve(entry.target);
            }
        });
    };

    useEffect(() => {
        document.documentElement.classList.add("reveal-loaded");

        const observer = new IntersectionObserver(handleIntersect, options);
        const targets = document.querySelectorAll(".reveal");
        const targetsHeader = document.querySelectorAll(".reveal-header");
        const targetYellow = document.querySelectorAll(".reveal-phones");

        targets.forEach((target) => {
            observer.observe(target);
        });

        targetsHeader.forEach((target) => {
            observer.observe(target);
        });

        targetYellow.forEach((target) => {
            observer.observe(target);
        });

        return () => {
            targets.forEach((target) => {
                observer.unobserve(target);
            });
            targetsHeader.forEach((target) => {
                observer.unobserve(target);
            });
            targetYellow.forEach((target) => {
                observer.unobserve(target);
            });
        };
    }, [options]);

    return <>{children}</>;
}
