import Footer from "../../widgets/Footer";
import Header from "../../widgets/HeaderBis";
import Navbar from "../../widgets/NavBar";
import Wrapper from "../../widgets/Wrapper";

import AmozPay from "../../assets/images/AmozPay.png"
import BaptisteBarbotin from "../../assets/images/BaptisteBarbotin.png"
import QuentinCapelle from "../../assets/images/QuentinCapelle.png"
import DylanAkpinar from "../../assets/images/DylanAkpinar.png"
import CorentinVidal from "../../assets/images/CorentinVidal.png"
import NicolasSaclier from "../../assets/images/NicolasSaclier.png"
import DavidGozlan from "../../assets/images/DavidGozlan.png"

const developpeurs = [
    {
        name: "Amoz Pay",
        job: "Fullstack Dev",
        picture: AmozPay
    },
    {
        name: "Baptiste Barbotin",
        job: "Lead Dev Backend",
        picture: BaptisteBarbotin
    },
    {
        name: "Quentin Capelle",
        job: "UI/UX Designer",
        picture: QuentinCapelle
    },
    {
        name: "Dylan Akpinar",
        job: "Magicien Android",
        picture: DylanAkpinar
    },
    {
        name: "Corentin Vidal",
        job: "Expert IA",
        picture: CorentinVidal
    },
    {
        name: "Nicolas Saclier",
        job: "Sorcier Backend",
        picture: NicolasSaclier
    },
    {
        name: "David Gozlan",
        job: "Expert Mobile",
        picture: DavidGozlan
    }
]

export default function Team() {
    return (
        <Wrapper>
            <Navbar />
            <Header
                title="Notre équipe !"
            />
            <div className="w-full">
                <div className="bg-white max-w-[1280px] w-full mx-auto py-8 md:py-12 px-4 md:px-12 rounded-lg flex flex-col md:flex-row flex-wrap items-center">
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {developpeurs.map((dev) => (
                            <div key={dev.name} className="flex flex-col items-center justify-center px-10 py-4 md:py-6 max-w-[275px] mx-auto">
                                <img src={dev.picture} alt={dev.name} className="rounded-full aspect-square" />
                                <span className="font-medium uppercase text-[#355379] text-sm md:text-base text-center mt-2">
                                    {dev.name}
                                </span>
                                <span className="text-primary-400 text-xs text-center uppercase tracking-[0.2rem]">
                                    {dev.job}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </Wrapper>
    )
}