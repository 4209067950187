import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./tests/reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./assets/css/index.css";

import Landing from "./pages/Landing/Landing";
import Use from "./pages/Use/Use";
import Earth from "./pages/Earth/Earth";
import WhatIsIt from "./pages/WhatIsIt/WhatIsIt";
import Page404 from "./pages/page404/404";
import Team from "./pages/Team/Team";

const container = document.getElementById("root");
const root = createRoot(container as any);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} errorElement={<Page404 />} />
                <Route path="/what-is-it" element={<WhatIsIt />} errorElement={<Page404 />} />
                <Route path="/use" element={<Use />} errorElement={<Page404 />} />
                <Route path="/earth" element={<Earth />} errorElement={<Page404 />} />
                <Route path="/team" element={<Team />} errorElement={<Page404 />} />
                <Route path="/*" element={<Page404 />} errorElement={<Page404 />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals(console.log);
