import Wrapper from "../../widgets/Wrapper";
import IndexNavbar from "../../widgets/NavBar";
import Header from "../../widgets/HeaderBis";
import Footer from "../../widgets/Footer";
import UseLeft from "../../widgets/UseLeft";
import UseRight from "../../widgets/UseRight";

export default function Use() {
    return (
        <Wrapper>
            <IndexNavbar />
            <Header
                title="Comment utiliser Timi ?"
            />
            <UseRight type="register" />
            <UseLeft type="login" />
            <UseRight type="home" />
            <UseLeft type="week" />
            <UseRight type="synthese" />
            <UseLeft type="chartpie" />
            <UseRight type="chartbar" />
            <Footer param="white" />
        </Wrapper>
    );
}
