import ErrorPicture from "../../assets/images/Pictures/page404.jpg"

export default function Page404() {
    return (
        <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
            <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                <div className="relative">
                    <div className="absolute text-center flex justify-center w-full">
                        <div className="">
                            <h1 className="my-2 text-gray-800 font-bold text-2xl">Cette page n'existe pas</h1>
                            <p className="my-2 text-gray-800">Désolé pour cela !</p>
                            <button
                                className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
                                onClick={() => window.history.back()}
                            >
                                Retourner d'où je viens
                            </button>
                        </div>
                    </div>
                    <div>
                        <img src={ErrorPicture} alt='for 404 page' width={900} />
                    </div>
                </div>
            </div>
        </div>
    );
}
