/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faBars } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link to="/" className="text-purple-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase">
            Timi
          </Link>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div
          className={"lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" + (navbarOpen ? " block" : " hidden")}
          id="example-navbar-warning"
        >
          <ul className="hidden md:flex flex-col lg:flex-row list-none mr-auto">
            <li className="flex items-center">
              <a className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/">
                <i className="text-blue-gray-400 far fa-file-alt text-lg leading-lg mr-2" /> Notre concept
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/what-is-it"
              >
                <i className="text-blue-gray-400 far fa-file-alt text-lg leading-lg mr-2" /> Qu'est-ce que Timi ?
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/use"
              >
                <i className="text-blue-gray-400 far fa-file-alt text-lg leading-lg mr-2" /> Comment utiliser Timi ?
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/earth"
              >
                <i className="text-blue-gray-400 far fa-file-alt text-lg leading-lg mr-2" /> L'intérêt pour vous et la planète
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/team"
              >
                <i className="text-blue-gray-400 far fa-file-alt text-lg leading-lg mr-2" /> Notre équipe
              </a>
            </li>
          </ul>
          <ul className="hidden md:flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center ml-auto">
              <a href="https://static.mytimi.fr/timi-eip/app_client-mobile-universal/dist/app-release.apk">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white active:bg-pink-700 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                  <FontAwesomeIcon icon={faArrowAltCircleDown} className="mr-1" /> Download
                </button>
              </a>
            </li>
          </ul>
          <ul className="flex md:hidden flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="/">
                <i className="text-blue-gray-400 fab fa-facebook text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Notre concept</span>
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/what-is-it"
              >
                <i className="text-blue-gray-400 fab fa-facebook text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Qu'est-ce que Timi ?</span>
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/use"
              >
                <i className="text-blue-gray-400 fab fa-facebook text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">Comment utiliser Timi ?</span>
              </a>
            </li>
            <li className="flex items-center">
              <a
                className="hover:text-blue-gray-500 text-blue-gray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                href="/earth"
              >
                <i className="text-blue-gray-400 fab fa-facebook text-lg leading-lg " />
                <span className="lg:hidden inline-block ml-2">L'intérêt pour vous et la planète</span>
              </a>
            </li>

            <li className="flex items-center">
              <a href="https://static.mytimi.fr/timi-eip/app_client-mobile-universal/dist/app-release.apk">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white active:bg-pink-700 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                  <FontAwesomeIcon icon={faArrowAltCircleDown} className="mr-1" /> Download
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
