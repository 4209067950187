import RegisterPicture from "../assets/images/Pictures/register.png"
import HomePicture from "../assets/images/Pictures/accueil.png"
import SynthesePicture from "../assets/images/Pictures/synthese.png"
import StatsPictures from "../assets/images/Pictures/stats2.png"

const data = [
    {
        id: "register",
        title: "Inscription",
        img: RegisterPicture,
        color: "pink",
        paragraph: "Bienvenue sur la page d'inscription de Timi ! Ici, tu peux créer ton compte personnel pour profiter pleinement de notre application. En t'inscrivant, tu bénéficieras d'un accès exclusif à tes tickets de caisse, ce qui te permettra de les retrouver facilement sans les égarer. De plus, tu pourras stocker tes cartes de fidélité pour les avoir toujours à portée de main lors de tes achats. Notre application offre également une synthèse financière détaillée, te permettant de suivre tes dépenses et de mieux gérer ton budget. Enfin, tu auras accès à diverses statistiques qui te donneront une vision claire de tes habitudes de consommation. Tout cela dans le but de rendre ton expérience avec Timi plus pratique, organisée et sécurisée.",
    },
    {
        id: "home",
        title: "Accueil",
        img: HomePicture,
        color: "blue",
        paragraph: "La page d'accueil sera ton premier contact avec l'application Timi, te donnant un aperçu complet de tes achats récents. Tu pourras facilement visualiser tes derniers tickets de caisse et filtrer les résultats par date ou par type de magasin. Grâce à une interface conviviale, tu pourras naviguer aisément entre tes achats passés et consulter les détails de chaque transaction. La page d'accueil est conçue pour te fournir une vue d'ensemble de tes dépenses, te permettant ainsi de mieux gérer ton budget et d'optimiser tes habitudes de consommation. Profite de cette fonctionnalité pour garder un contrôle total sur tes finances et bénéficier d'une expérience utilisateur fluide et personnalisée.",
    },
    {
        id: "synthese",
        title: "Synthèse",
        img: SynthesePicture,
        color: "purple",
        paragraph: "Bienvenue sur la page Synthèse de Timi, ta source complète pour consulter tes dépenses par jour, semaine, mois et année. Ici, tu peux explorer tes transactions passées et comparer facilement avec les périodes précédentes. Découvre les jours, semaines, mois ou années où tu as le plus dépensé, ainsi que ceux où tu as le moins dépensé. Grâce à cette fonctionnalité, tu peux analyser tes habitudes de dépenses et identifier les moments clés de ta consommation. Cette vue d'ensemble te permet de mieux comprendre ta gestion financière, de détecter les fluctuations de tes dépenses et d'ajuster tes comportements en conséquence. Que tu souhaites optimiser tes économies ou améliorer ta planification budgétaire, la page Synthèse te donne les outils nécessaires pour prendre le contrôle de tes finances. N'hésite pas à explorer cette fonctionnalité pour obtenir une vision complète et éclairée de ta situation financière et pour améliorer ton expérience de gestion budgétaire avec Timi !",
    },
    {
        id: "chartbar",
        title: "Statistiques",
        img: StatsPictures,
        color: "green",
        paragraph: "la page Statistique en Bâton de Timi, permet d'explorer tes dépenses à travers un diagramme en bâton clair et informatif. Ce graphique te permet d'identifier le montant de tes dépenses pour chaque jour, semaine, mois ou année, ainsi que la catégorie dans laquelle la dépense a été faite. Avec une vue complète des dépenses par catégorie, tu peux facilement accéder à un compte rendu détaillé de tes transactions, ce qui t'aide à mieux comprendre comment tu répartis tes dépenses. Cette fonctionnalité t'offre une analyse approfondie de tes habitudes de consommation, te permettant ainsi de prendre des décisions éclairées en matière de budget et d'ajuster tes dépenses en fonction de tes priorités financières. Explore la page Statistique en Bâton pour optimiser ta gestion budgétaire et avoir un suivi détaillé de tes dépenses avec Timi !"
    },
];

export default function Caracteristics({ type }: Readonly<{ type: string }>) {
    const item = data.find((i) => i.id === type);

    return (
        <section className="mt-24 md:mt-20 relative bg-gray-50">
            <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>

            <div className="mx-auto">
                <div className="flex flex-wrap items-center">
                    <div className="w-10/12 md:w-4/12 lg:w-3/12 px-12 md:px-4 ml-auto mt-16">
                        <div className="relative flex flex-col min-w-0 break-words bg-transparent mb-6  rounded-lg">
                            <img
                                alt="..."
                                src={item?.img}
                                style={{
                                    transform: "scale(1) perspective(1040px) rotateY(11deg) rotateX(-2deg) rotate(-2deg)",
                                }}
                                width={200}
                            />
                        </div>
                    </div>

                    <div className="w-full sm:w-10/12 md:w-6/12 px-4 ml-auto mr-auto">
                        <h3 className={`text-3xl mb-2 font-semibold leading-normal text-${item?.color}-500 tracking-wide reveal-7`}>{item?.title}</h3>
                        <p className="text-lg font-light text-justify leading-relaxed mb-4 text-blue-gray-500 reveal-8">
                            {item?.paragraph}
                        </p>
                    </div>
                </div>
            </div>
            <div className="-bottom-20 top-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute top-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 0 0 0 100"></polygon>
                </svg>
            </div>
        </section>
    );
}
