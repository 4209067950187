import { faAnglesRight, faLeaf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EarthPicture from "../assets/images/Pictures/ecologie.jpg"

export default function Ecology() {
    return (
        <section className="mx-auto overflow-hidden pb-20">
            <div className="flex flex-wrap items-center">
                <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-24">
                    <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                        <div className="text-green-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white">
                            <FontAwesomeIcon icon={faLeaf} className="text-xl" />
                        </div>
                        <h3 className="text-3xl mb-2 font-semibold leading-normal text-green-500 tracking-wide">Ecologie</h3>
                    </div>
                    <p className="text-lg font-light text-justify leading-relaxed mb-4 text-blue-gray-500">
                        La dématérialisation des tickets de caisse constitue une démarche écologique en réduisant la consommation de papier, ce qui limite la
                        déforestation et diminue l'impact environnemental lié à la production et à l'élimination du papier. C'est une contribution précieuse
                        pour une planète plus durable.
                    </p>
                    <a href="/earth#ecolo" rel="noreferrer" className="text-green-500 hover:ml-4 transition-all">
                        <FontAwesomeIcon icon={faAnglesRight} className="mr-2" />
                        En savoir plus
                    </a>
                </div>

                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto -mt-36">
                    <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                        <img
                            alt="ecologie"
                            src={EarthPicture}
                            className="w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
