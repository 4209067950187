import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeaf, faMobileScreenButton, faShieldHalved, faSitemap } from "@fortawesome/free-solid-svg-icons";
import CaracteristicsPicture from "../assets/images/Pictures/fourIcons.jpg"

export default function Caracteristics() {
    return (
        <section className="mt-24 md:mt-20 relative bg-gray-50">
            <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-center">
                    <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto">
                        <div className="relative flex flex-col min-w-0 break-words bg-transparent w-full mb-6  rounded-lg">
                            <img
                                alt="..."
                                src={CaracteristicsPicture}
                                className="w-full align-middle rounded-t-lg shadow-lg"
                                style={{
                                    transform: "scale(1) perspective(1040px) rotateY(11deg) rotateX(-2deg) rotate(-2deg)",
                                }}
                            />
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 px-4">
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-6/12 px-4">
                                <div className="relative flex flex-col mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-green-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                            <FontAwesomeIcon icon={faLeaf} />
                                        </div>
                                        <p className="text-xl mb-1 font-semibold text-green-500 tracking-wide" style={{ fontFamily: 'Manjari' }}>Ecologique</p>
                                        <p className="mb-4 text-blue-gray-500">Réduit la consommation de papier, préservant ainsi l'environnement.</p>
                                    </div>
                                </div>
                                <div className="relative flex flex-col min-w-0">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-pink-400 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                            <FontAwesomeIcon icon={faMobileScreenButton} />
                                        </div>
                                        <p className="text-xl mb-1 font-semibold text-pink-400 tracking-wide" style={{ fontFamily: 'Manjari' }}>Pratique</p>
                                        <p className="mb-4 text-blue-gray-500">Accès facile et instantané aux reçus depuis son téléphone portable.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 mt-4">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-purple-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                            <FontAwesomeIcon icon={faSitemap} />
                                        </div>
                                        <p className="text-xl mb-1 font-semibold text-purple-500 tracking-wide" style={{ fontFamily: 'Manjari' }}>Organisation</p>
                                        <p className="mb-4 text-blue-gray-500">Facilite le suivi des dépenses et la gestion des achats.</p>
                                    </div>
                                </div>
                                <div className="relative flex flex-col min-w-0">
                                    <div className="px-4 py-5 flex-auto">
                                        <div className="text-blue-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                                            <FontAwesomeIcon icon={faShieldHalved} />
                                        </div>
                                        <p className="text-xl mb-1 font-semibold text-blue-500 tracking-wide" style={{ fontFamily: 'Manjari' }}>Sécurité</p>
                                        <p className="mb-4 text-blue-gray-500">Évite les pertes de tickets, sauvegarde numérique des preuves d'achat.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-bottom-20 top-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute top-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 0 0 0 100"></polygon>
                </svg>
            </div>
        </section>
    );
}
