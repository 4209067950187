import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrgaBis from "../assets/images/orgaBis.jpg";
import { faChartSimple, faFolder, faGears, faSitemap } from "@fortawesome/free-solid-svg-icons";
import OrganizationPicture from "../assets/images/Pictures/organisation.jpg"

export default function Organisation({ color = "black" } : Readonly<{ color?: string }>) {
    return (
        <section className={`relative ${ color === "black" ? "bg-white-50" : "bg-gray-50 pb-8"} mx-auto px-4 pt-24 z-1`}>
           {color !== "black" && (
                <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                    </svg>
                </div>
           )}
            <div className="items-center flex flex-wrap">
                <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
                    <div className="md:pr-12">
                        <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                            <div className="text-purple-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white">
                                <FontAwesomeIcon icon={faSitemap} className="text-xl" />
                            </div>
                            <h3 className="text-3xl font-semibold text-purple-500 tracking-wide">Organisation</h3>
                        </div>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blue-gray-500 text-justify">
                            La dématérialisation des tickets de caisse facilite l'organisation financière. Les utilisateurs peuvent classer et gérer leurs reçus
                            par catégorie, exporter des rapports détaillés et analyser leurs achats, simplifiant ainsi la gestion des dépenses personnelles ou
                            professionnelles en un seul endroit intuitif et accessible à tout moment.
                        </p>
                        <ul className="list-none mt-6">
                            <li className="py-2">
                                <div className="flex items-center">
                                    <div>
                                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-500 bg-purple-50 mr-3">
                                            <FontAwesomeIcon icon={faFolder} />
                                        </span>
                                    </div>
                                    <div>
                                        <h4 className="text-purple-500">Classification</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="flex items-center">
                                    <div>
                                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-500 bg-purple-50 mr-3">
                                            <FontAwesomeIcon icon={faGears} />
                                        </span>
                                    </div>
                                    <div>
                                        <h4 className="text-purple-500">Gestion</h4>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="flex items-center">
                                    <div>
                                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-500 bg-purple-50 mr-3">
                                            <FontAwesomeIcon icon={ faChartSimple } />
                                        </span>
                                    </div>
                                    <div>
                                        <h4 className="text-purple-500">Rapports</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
                    <img
                        alt="..."
                        className="max-w-full rounded-lg shadow-xl relative z-40"
                        style={{
                            transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                        }}
                        src={color === "black" ? OrganizationPicture : OrgaBis }
                    />
                </div>
            </div>
        </section>
    );
}
