import { faDroplet, faLeaf, faTree } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EcologyRenforced() {
    return (
        <section className="mt-8 md:mt-16 pb-8 relative bg-gray-50" id="ecolo">
            <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="flex flex-wrap items-center">
                <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-16">
                    <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                        <div className="text-green-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white reveal-1">
                            <FontAwesomeIcon icon={faLeaf} className="text-xl" />
                        </div>
                        <h3 className="text-3xl mb-2 font-semibold leading-normal text-green-500 tracking-wide reveal-1">Ecologie</h3>
                    </div>
                    <p className="text-lg font-light text-justify leading-relaxed mb-4 text-blue-gray-500 reveal-2">
                        La dématérialisation des tickets de caisse via une application mobile et des QR codes offre un avantage écologique significatif par
                        rapport à l'utilisation de mails. En évitant l'impression papier et les ressources énergétiques nécessaires pour l'envoi de mails, cette
                        approche réduit considérablement l'empreinte carbone. <br />
                        <br />
                        De plus, l'utilisation de QR codes permet une récupération instantanée des reçus, sans nécessiter une connexion Internet constante pour
                        les consulter. Cela favorise une démarche durable en réduisant la consommation de papier et d'énergie, contribuant ainsi à la
                        préservation de l'environnement. La dématérialisation des tickets de caisse par le biais d'une application mobile et des QR codes
                        représente un pas vers un mode de consommation plus responsable et respectueux de la planète.
                    </p>
                </div>

                <div className="w-full md:w-5/12 px-4 mr-auto ml-auto -mt-36">
                    <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                        <img
                            alt="ecologie"
                            src="https://www.zupimages.net/up/23/29/xmm7.jpg"
                            className="hidden md:flex w-full align-middle rounded absolute shadow-lg max-w-100-px z-3 left-145-px -top-29-px reveal-phones"
                        />
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-row flex-wrap md:flex-nowrap justify-around gap-4 px-4">
                <div className="my-4 w-full lg:w-6/12 px-4">
                    <div className="bg-green-500 shadow-lg rounded-lg text-center p-8 h-78">
                        <FontAwesomeIcon icon={ faTree } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">25 millions d'arbres abattus par an</p>
                        <p className="text-sm text-white mt-2 text-justify">
                            Chaque année, l'impression de tickets de caisse entraîne l'abattage de près de 25 millions d'arbres dans le monde. Ces arbres sont
                            utilisés pour produire la quantité massive de papier nécessaire à l'émission de reçus papier dans les magasins, les supermarchés et
                            les commerces en général. Cette pratique a un impact significatif sur la déforestation et la biodiversité des écosystèmes
                            forestiers.
                        </p>
                    </div>
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4">
                    <div className="border-2 border-green-500 shadow-lg rounded-lg text-center p-8 h-74">
                        <FontAwesomeIcon icon={faDroplet} className="shadow-md rounded-full mx-auto p-4 bg-green-500 text-white" />
                        <p className="text-lg text-green-500 mt-4 font-semibold">250 000 litres d'eau utilisés par an</p>
                        <p className="text-sm text-green-500 mt-2 text-justify">
                            L'impression de tickets de caisse a un impact significatif sur la consommation d'eau. Chaque année, environ 250 000 litres d'eau
                            sont utilisés dans le processus de production du papier nécessaire à l'émission des reçus papier. Cette quantité d'eau est
                            nécessaire pour la transformation de la pâte de bois en papier, pour les différentes étapes du processus de fabrication et pour le
                            refroidissement des machines.
                        </p>
                    </div>
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4">
                    <div className="bg-green-500 shadow-lg rounded-lg text-center p-8 h-78">
                        <FontAwesomeIcon icon={faTree} className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">30 milliards de tickets imprimés par an</p>
                        <p className="text-sm text-white mt-2 text-justify">
                            haque année, en France, environ 30 milliards de tickets de caisse sont jetés, ce qui représente une quantité massive de déchets
                            papier dispersés dans la nature. Ces tickets, sont souvent recouvert de produits chimiques pour l'impression. Lorsqu'ils sont abandonnés dans la nature, les tickets de
                            caisse peuvent être emportés par le vent ou les cours d'eau, se retrouvant dans les océans et menaçant la faune marine.
                        </p>
                    </div>
                </div>
            </div>
            <div className="-bottom-20 top-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute top-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-gray-50 fill-current" points="2560 0 0 0 0 100"></polygon>
                </svg>
            </div>
        </section>
    );
}
