import Wrapper from "../../widgets/Wrapper";
import IndexNavbar from "../../widgets/NavBar";
import Header from "../../widgets/Header";
import Footer from "../../widgets/Footer";
import Caracteristics from "../../widgets/Caracteristics";
import PraticityLong from "../../widgets/PraticityLong";
import Organisation from "../../widgets/Organisation";

export default function WhatIsIt () {
    return (
        <Wrapper>
            <IndexNavbar />
            <Header title="Qu'est-ce que Timi ?" description={`Découvrez "Timi", l'application révolutionnaire qui dématérialise vos tickets de caisse pour les avoir toujours à portée de main sur votre téléphone portable. Simplifiez votre gestion des reçus et dites adieu aux tickets papier encombrants.`} />
            <Caracteristics />
            <PraticityLong />
            <Organisation color="white" />
            <Footer />
        </Wrapper>
    )
}