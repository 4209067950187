import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer({ param }: Readonly<{ param?: string }>) {
    console.log(param);
    return (
        <footer className={`relative bg-${param !== undefined ? `${param} mt-16` : "gray-50"} pt-8 pb-6`}>
            <div
                className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                style={{ transform: "translateZ(0)" }}
            >
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className={`text-${param ?? "gray-50"} fill-current`} points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap text-center lg:text-left">
                    <div className="w-full lg:w-6/12 px-4">
                        <h4 className="text-3xl font-semibold text-pink-500">Restons en contact !</h4>
                        <h5 className="text-lg mt-0 mb-2 text-blue-gray-500">Suivez notre aventure sur nos réseaux sociaux</h5>
                        <div className="mt-6 lg:mb-0 mb-6">
                            <button
                                className="bg-white text-purple-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                type="button"
                                aria-label="Instagram"
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </button>
                            <button
                                className="bg-white text-purple-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                                type="button"
                                aria-label="Facebook"
                            >
                                <FontAwesomeIcon icon={faFacebookSquare} />
                            </button>
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 ">
                        <div className="flex flex-wrap items-top mb-6">
                            <div className="w-full lg:w-6/12 ml-auto px-4">
                                <span className="block uppercase text-pink-500 text-sm font-semibold mb-2">Liens utiles</span>
                                <ul className="list-unstyled">
                                    <li>
                                        <a className="text-blue-gray-500 hover:text-blue-gray-800 font-semibold block pb-2 text-sm" href="/#">
                                            Conditions d'utilisation
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-blue-gray-500 hover:text-lime-800 font-semibold block pb-2 text-sm" href="/#">
                                            Politique de confidentialité
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-purple-300" />
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div className="text-sm text-blue-gray-500 font-semibold py-1">Copyright © Timi Team</div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
