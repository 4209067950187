/*eslint-disable*/
import IndexNavbar from "../../widgets/NavBar";
import Footer from "../../widgets/Footer";

import "../../assets/css/Landing.css";
import Wrapper from "../../widgets/Wrapper";
import Header from "../../widgets/Header";
import Caracteristics from "../../widgets/Caracteristics";
import Ecology from "../../widgets/Ecology";
import Praticity from "../../widgets/Praticity";
import Organisation from "../../widgets/Organisation";
import Security from "../../widgets/Security";
import BoxOnTwo from "../../widgets/BoxOnTwo";

export default function Index() {
    return (
        <Wrapper>
            <IndexNavbar />
            <Header title="Timi - Dites adieu à vos tickets de caisse." description={`Découvrez "Timi", l'application révolutionnaire qui dématérialise vos tickets de caisse pour les avoir toujours à portée de main sur votre téléphone portable. Simplifiez votre gestion des reçus et dites adieu aux tickets papier encombrants.`} />
            <Caracteristics />
            <Ecology />
            <Praticity />
            <Organisation />
            <Security />
            <BoxOnTwo />
            <Footer />
        </Wrapper>
    );
}
