import { faAddressCard, faArrowPointer, faEuro, faMagnifyingGlass, faMobileScreen, faMobileScreenButton, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Praticity() {
    return (
        <section className="mt-8 md:mt-8 relative bg-white-50">
            <div className="flex flex-wrap items-center pb-16">
                <div className="w-full md:w-8/12 px-12 md:px-4 ml-auto mr-auto mt-24">
                    <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                        <div className="text-pink-400 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white">
                            <FontAwesomeIcon icon={ faMobileScreenButton } className="text-xl" />
                        </div>
                        <h3 className="text-3xl mb-2 font-semibold leading-normal text-pink-400 tracking-wide">Pratique</h3>
                    </div>
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blue-gray-500 text-justify">
                        La dématérialisation des tickets de caisse offre une praticité inégalée. Les utilisateurs ont un accès instantané à leurs reçus depuis
                        leur téléphone portable, éliminant ainsi le besoin de conserver physiquement les tickets. Cela facilite le suivi des dépenses, la
                        recherche rapide de reçus, et simplifie la gestion administrative. Plus besoin de fouiller dans les poches ou les sacs pour retrouver un
                        ticket, tout est désormais accessible en quelques clics sur l'application.
                    </p>
                </div>
                <div className="w-full flex flex-col md:flex-row justify-around gap-4 px-4">
                    <div className="bg-green-500 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faMobileScreen } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Dématérialisation</p>
                    </div>
                    <div className="bg-purple-500 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faMagnifyingGlass } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Recherche rapide</p>
                    </div>
                    <div className="bg-pink-500 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faArrowPointer } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Accès instantané</p>
                    </div>
                    <div className="bg-amber-700 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faEuro } className="shadow-md rounded-full mx-auto p-4  px-5 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Suivi des dépenses</p>
                    </div>
                    <div className="bg-blue-700 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faAddressCard } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Carte de fidélité</p>
                    </div>
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                        <FontAwesomeIcon icon={ faUser } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                        <p className="text-lg text-white mt-4 font-semibold">Expérience utilisateur améliorée</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
