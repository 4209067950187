import Wrapper from "../../widgets/Wrapper";
import IndexNavbar from "../../widgets/NavBar";
import Header from "../../widgets/Header";
import Footer from "../../widgets/Footer";
import EcologyRenforced from "../../widgets/EcologyRenforced";
import PraticityRenforced from "../../widgets/PraticityRenforced";

export default function Earth () {
    return (
        <Wrapper>
            <IndexNavbar />
            <EcologyRenforced />
            <PraticityRenforced />
            <Footer />
        </Wrapper>
    )
}