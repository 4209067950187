import { faAddressCard, faArrowPointer, faEuro, faMagnifyingGlass, faMobileScreen, faMobileScreenButton, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Praticity() {
    return (
        <section className="mt-8 md:mt-16 relative bg-white">
            <div className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-white fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <div className="flex flex-wrap items-center py-16">
                <div className="w-full md:w-full px-4 mr-auto ml-auto">
                    <div className="w-full md:w-8/12 px-12 md:px-4 ml-auto mr-auto mb-16">
                        <div className="relative flex flex-row items-center gap-4 min-w-0 w-full pt-8">
                            <div className="text-pink-400 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-6 shadow-lg rounded-full bg-white">
                                <FontAwesomeIcon icon={ faMobileScreenButton } className="text-xl" />
                            </div>
                            <h3 className="text-3xl mb-2 font-semibold leading-normal text-pink-400 tracking-wide">Pratique</h3>
                        </div>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blue-gray-500 text-justify">
                            La dématérialisation des tickets de caisse offre une praticité inégalée. Les utilisateurs ont un accès instantané à leurs reçus depuis
                            leur téléphone portable, éliminant ainsi le besoin de conserver physiquement les tickets. Cela facilite le suivi des dépenses, la
                            recherche rapide de reçus, et simplifie la gestion administrative. Plus besoin de fouiller dans les poches ou les sacs pour retrouver un
                            ticket, tout est désormais accessible en quelques clics sur l'application.
                        </p>
                    </div>
                    <div className="justify-center flex flex-wrap relative">
                        <div className="my-4 w-full lg:w-4/12 px-4">
                            <div className="bg-green-500 shadow-lg rounded-lg text-center p-8">
                                <FontAwesomeIcon icon={ faMobileScreen } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Dématérialisation</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    La dématérialisation des tickets de caisse élimine le fardeau des reçus papier en les stockant numériquement. Les
                                    utilisateurs peuvent dire adieu aux tiroirs encombrés et aux pertes de tickets, tout en contribuant à la préservation de
                                    l'environnement grâce à une démarche plus écologique.
                                </p>
                            </div>
                            <div className="bg-pink-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                <FontAwesomeIcon icon={ faArrowPointer } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Accès instantané</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    L'accès instantané aux reçus depuis le téléphone portable offre une commodité exceptionnelle. Que ce soit lors d'un retour en
                                    magasin, d'une déclaration fiscale ou d'un suivi budgétaire, les utilisateurs ont toutes les informations à portée de main.
                                </p>
                            </div>
                        </div>
                        <div className="my-4 w-full lg:w-4/12 px-4 lg:mt-16">
                            <div className="bg-purple-500 shadow-lg rounded-lg text-center p-8">
                                <FontAwesomeIcon icon={ faMagnifyingGlass } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Recherche rapide</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    Avec l'application mobile, la recherche des tickets devient un jeu d'enfant. Les utilisateurs peuvent simplement saisir des
                                    mots-clés ou des dates pour retrouver rapidement le reçu qu'ils cherchent, facilitant la gestion des achats et la
                                    vérification des transactions.
                                </p>
                            </div>
                            <div className="bg-amber-700 shadow-lg rounded-lg text-center p-8 mt-8">
                                <FontAwesomeIcon icon={ faEuro } className="shadow-md rounded-full mx-auto p-4  px-5 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Suivi des dépenses</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    Grâce à la fonctionnalité de suivi des dépenses, les utilisateurs peuvent catégoriser leurs achats, établir des budgets et
                                    visualiser leurs habitudes de consommation. Cette vue d'ensemble favorise une gestion financière plus éclairée et responsable.
                                </p>
                            </div>
                        </div>
                        <div className="my-4 w-full lg:w-4/12 px-4">
                            <div className="bg-blue-700 shadow-lg rounded-lg text-center p-8 mt-8">
                                <FontAwesomeIcon icon={ faAddressCard } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Carte de fidélité</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    Intégrer des programmes de fidélité à l'application simplifie le processus de cumul et d'utilisation des points. Les utilisateurs
                                    peuvent bénéficier de récompenses sans avoir à transporter de cartes physiques, tout est intégré dans leur téléphone.
                                </p>
                            </div>
                            <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8 mt-8">
                                <FontAwesomeIcon icon={ faUser } className="shadow-md rounded-full mx-auto p-4 bg-white" />
                                <p className="text-lg text-white mt-4 font-semibold">Expérience utilisateur améliorée</p>
                                <p className="text-sm text-white mt-2 text-justify">
                                    En combinant tous ces avantages pratiques, l'application offre une expérience utilisateur fluide et agréable. Elle transforme la
                                    gestion administrative fastidieuse en une expérience intuitive et efficace, libérant ainsi du temps et facilitant la vie
                                    quotidienne.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-bottom-20 top-auto left-0 right-0 w-full absolute h-20" style={{ transform: "translateZ(0)" }}>
                <svg
                    className="absolute top-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon className="text-white fill-current" points="2560 0 0 0 0 100"></polygon>
                </svg>
            </div>
        </section>
    );
}
